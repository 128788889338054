.UserDetail {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 0 !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 44;
    overflow: auto;
    padding-bottom: 100px;

    .user-info {
        overflow: auto;
        background-color: white;
        color: black;
        padding: 30px;
        margin: 100px auto auto;
        position: relative;
        width: 100%;
        max-width: 600px;
    }

    .info-table {
        width: 100%;
        font-size: 1.1em;

        td {
            border: 1px solid rgb(0, 128, 223);
            padding: 8px;
            border-radius: 10px;
        }
    }

    ul {
        padding-top: 0;
        margin: 0;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
        font-size: 20px;
        color: #c20000;
    }
}