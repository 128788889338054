.RappiAdvice{
  padding: 30px;
  background: rgb(0,59,138);
  text-align: left;
  position: relative;
  .advice-title{
    padding-right: 90px;
    font-size: 1.8em;
  }
  .the-advice{
    font-family: PlutoRegular, sans-serif;
    font-size: 1.4em;
    .example{
      color: rgb(118,232,6);
      margin: 1.6em 0;
    }
  }
  .btn-container{
    text-align: center;
    padding: 10px 0;
  }
  .accept{
    border: none;
    background: none;
    color: white;
    font-family: PlutoBold, sans-serif;
    font-size: 1.4em;
    background: rgb(43,156,248);
    padding: 0.4em 2.5em;
    border-radius: 1em;
    cursor: pointer;
  }
  .rocket{
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 8em;
  }
}
