@import "breakpoints";
@import "colors";

html,body{
  font-family: PlutoBold, sans-serif;
  font-size: 14px;
  color: $text-color;
  padding: 0;
  margin: 0;
  background-color: #04366D;
  position: relative;
}
*{
  box-sizing: border-box;
}

b, strong{
  font-family: PlutoHeavy, sans-serif;
  font-weight: 600;
}
a{
  color: $text-color;
  text-decoration: none !important;
  &:visited{
    color: $text-color;
    text-decoration: none;
  }
}

h1, h2, h3{
    font-family: PlutoHeavy, sans-serif;
    font-weight: 600;
}
.App{
  .oreo-button {
    background-position: center;
    background-size: cover;
    background-color: transparent;
    border: none;
    width: 219px;
    height: 107px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    padding-bottom: 10px;
    z-index: 10;
  }
  .register-button{
    background-image:  url("../../assets/images/login-and-register/boton_registrarte_web.png");
  }
  .login-button{
    background-image:  url("../../assets/images/login-and-register/boton_iniciar_web.png");
  }

  .captcha-container{
    text-align: center;
    margin-top: 15px;
  }

  .clearfix{
    clear: both;
    padding: 0 !important;
  }
}

.web{
  @media only screen and (max-width: $screen-md-min) {
    display: none;
  }
}
.mobile{
  @media only screen and (min-width: $screen-md-min) {
    display: none;
  }
}


.btn-export {
  width: 200px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 0;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}