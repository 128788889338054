@import "../../../assets/styles/breakpoints";

.GeneralContainer.Prizes{

  .prizes-container{
    width: 100%;
    @media only screen and (max-width: $screen-md-min){
      padding-top: 120px;
    }
  }
  .prize-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    margin: 0 auto 50px;
    @media only screen and (max-width: $screen-md-min){
      flex-direction: column;
    }

    .prize-block{
      width: 18vw;
      text-align: center;
      @media only screen and (max-width: $screen-md-min){
        width: 100%;
        display: flex;
        .prize-image-container, .prize-info{
          flex: 1;
        }
      }
      .prize-img{
        width: 100%;
      }
      .divider{
        height: 2px;
        background: blue;
        margin: 4px auto;
      }
      .prize-title{
        padding: 0.2vw;
        font-size: 1.05vw;
        border-radius: 0.45em;
        width: 90%;
        margin: 1em auto;
        @media only screen and (max-width: $screen-md-min){
          font-size: 0.8em;
          padding: 0.4em 0.2em;
        }
      }
      .first-prize-desc{
        display: flex;
        justify-content: flex-end;
      }
      &.prize-0 {
        position: relative;
        top: 10px;
        .prize-title {
          background: rgb(35, 48, 139);
        }
      }
      &.prize-1 .prize-title{
        background: rgb(164,31,240);
      }
      &.prize-2 .prize-title{
        background: rgb(208,181,33);
      }
      &.prize-3 {
        position: relative;
        top: -1.3vw;
        .prize-title {
          background: rgb(79, 184, 241);
        }
        .prize-description{
          font-size: 1.3vw;
          @media only screen and (max-width: $screen-md-min){
            font-size: 1.2em;
          }
        }
      }
      .prize-description{
        text-align: right;
        width: 90%;
        margin: auto;
        @media only screen and (max-width: $screen-md-min){
            text-align: left;
        }
      }
    }
  }
  .title{
    font-size: 5vw;
    margin: 0.2em;
    text-align: center;
    @media only screen and (max-width: $screen-md-min){
        font-size: 2em;
    }
  }

  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 22vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }

}
