@import "../../../assets/styles/breakpoints";

.GeneralContainer.RankingMenu{

  .ranking-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 140px;
    @media only screen and (max-width: $screen-md-min) {
      padding-top: 120px;
    }
    .title{
      text-align: center;
      font-size: 3.2em;
      margin: 0;
      .up{
        font-size: 1.2em;
      }
      @media only screen and (max-width: $screen-md-min) {
        font-size: 1.6em;
      }
    }
    .options{
      padding-top: 40px;
      display: flex;

      @media only screen and (max-width: $screen-md-min) {
        flex-direction: column;
        margin-bottom: 120px;
      }

      .options-row{
        display: flex;
        flex-direction: column;
      }
      .option{
        padding: 5px 30px;
        text-align: right;
        .btn-img{
          height: 135px;
          @media only screen and (max-width: $screen-md-min) {
            height: auto;
            width: 80vw;
          }
        }
        .prize-desc{
          display: flex;
          justify-content: flex-end;
          text-align: right;
          font-size: 0.9em;
          position: relative;
          top: -1em;
          .text{
            margin: 0;
          }
          .prize-icon{
            width: 60px;
            margin-left: 10px;

          }
        }
      }
    }
  }

  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 22vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
  .winners-button{
    background: none;
    border: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  .winners-menu{
    background: #013d7b;
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 1.1em;
    padding-top: 20px;
    position: relative;
    top: -40px;

    .winner-link{
      display: block;
      padding: 20px;
      width: 80%;
      margin: auto;
      &:first-child{
        border-bottom: 2px solid #fff;
      }
    }
  }
}
