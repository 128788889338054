@import "src/assets/styles/breakpoints";

.Countdown{
    background: url("./assets/backgorund_countdown_web.jpg") no-repeat center;
    background-size: 100% 100%;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    @media screen and (max-width: $screen-md-min) {
        background-image: url("./assets/back_oreo_countdown_mobile.jpg");
        overflow: hidden;
    }
    .header-img{
        background: url("./assets/emojis_countdown_web.png") no-repeat top;
        background-size: 100% auto;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 0;
        top: 0;
        @media screen and (max-width: $screen-md-min) {
            background-image: url("./assets/emojis_countdown_mobile.png");
        }
    }
    .title{
        margin: 0;
    }
    .logo{
        width: 18vw;
        @media only screen and (max-width: $screen-md-min) {
            margin-top: 34vw;
            width: 70vw;
        }
    }
    .subtitle{
        font-size: 3em;
        margin: 0 0 20px 0;
        @media only screen and (max-width: $screen-md-min) {
            font-size: 1.9em;
        }
    }
    .content{
        position: relative;
        z-index: 1;
        text-align: center;
        padding: 40px;
        @media screen and (max-width: $screen-md-min) {
            padding: 84px 20px;
        }
        .count-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 0.8em;
            position: relative;
            font-size: 90px;
            @media screen and (max-width: $screen-xxl-min) {
                font-size: 60px;
            }
            @media screen and (max-width: $screen-xl-min) {
                font-size: 50px;
            }
            @media screen and (max-width: $screen-lg-min) {
                font-size: 40px;
            }
            @media screen and (max-width: $screen-md-min) {
                font-size: 30px;
            }
            @media screen and (max-width: $screen-sm-min) {
                font-size: 27px;
            }
            .count-item{
                margin: 0 0.16em;
                @media screen and (max-width: $screen-md-min) {
                    margin: 0 5px;
                }
            }
            .count-number{
                background: white;
                color: black;
                display: inline-block;
                margin: 0.08em;
                padding: 0.18em 0.15em;
                border-radius: 0.22em;
                box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.3);
                width: 1.1em;
                font-family: PlutoHeavy, sans-serif;

            }
            .time-name{
                font-size: 0.4em;
                margin: 0.6em 0;

            }
        }
        .count-corner{
            position: absolute;
            width: 10vw;
            top: -4.5vw;
            &.left{
                left: -6vw;
                width: 12vw;
            }
            &.right{
                right: -5.5vw;
            }
        }
    }
    .description{
        font-size: 1.7em;
        margin: 0;
        max-width: 770px;
        display: inline-block;
        font-family: PlutoRegular, sans-serif;
        @media only screen and (max-width: $screen-md-min) {
            font-size: 1.2em;
            max-width: 80vw;
        }
    }
}
