@import "src/assets/styles/breakpoints";
@import "../../game-sizes";

.Instructions{
  position: relative;
  .game-frame{
    background: url("../../assets/layout/tablero_juego_web.png") no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: $frame-height;
    padding: $frame-height*0.04 (100vw - $frame-width +4vw)/2;
    z-index: 1;
    position: relative;
    cursor: pointer;
    @media only screen and (max-width: $screen-md-min) {
      background-image: url("../../assets/layout/tablero_juego_mobile.png");
      background-size: 120% 110%;
      height: $frame-height-mob;
    }
  }
  .avatar{
    position: absolute;
    left: 15vw;
    top: -4vh;
    width: 11vh;
    z-index: 0;
    @media only screen and (max-width: $screen-md-min) {
      width: 8vh;
      top: -2.5vh;
    }
  }
  .start-countdown{
    position: absolute;
    top: -4vh;
    left: 26vw;
    font-size: 4vh;
    font-family: PlutoRegular, sans-serif;
    p{
      margin: 0;
    }
    .time{
      font-family: PlutoBold, sans-serif;
      font-size: 1.2em;
    }
    @media only screen and (max-width: $screen-md-min) {
      top: -1vh;
      left: initial;
      right: 15vw;
      font-size: 2vh;
    }
  }
  .instructions-content{
    display: flex;
    text-align: center;
    >div{
      flex: 1;
    }
    .recipe-image-cont{
      padding-top: 10vh;
      .recipe-image{
        max-width: 50vh;
      }
      .recipe-name{
        font-size: 3.5vh;
        margin: 0;
      }
      @media only screen and (max-width: $screen-md-min) {
        padding-top: 15vh;
        .recipe-image {
          max-width: 28vh;
        }
        .recipe-name {
          font-size: 2.8vh;
          max-width: 70vw;
          margin: auto;
        }
      }
    }

    .recipe-desc {
      margin-top: 6vh;
      font-size: 2.5vh;
      height: 51vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        margin: 1.5vh;
        .big {
          font-size: 1.74em;
        }
      }
      .separator{
        background: aqua;
        width: 80%;
        height: 3px;
      }
      .ingredient-image{
        height: 6vh;
        margin: 0 1.5vh 1vh 1.5vh;
      }
      .ingredient-image{
        height: 6vh;
        margin: 0 1.5vh 1vh 1.5vh;
      }
      .final-desc{
        margin: 1.5vh 0;
      }
      @media only screen and (max-width: $screen-md-min) {
        margin-top: 0;
        font-size: 1.8vh;
        .ingredient-image{
          height: 5vh;
          margin: 0 1vh 1vh 1vh;
        }
      }
    }
  }
  .controls{
    padding-top: 15vh;
    overflow: hidden;
    .control-animation {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: instructions-bowl-animation 6s linear infinite;
      .cursor-block {
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        .arrow {
          width: 3vh;
          margin: 0 2vh;
          &.left {
            transform: rotate(180deg);
          }
        }
        .cursor {
          width: 5vh;
        }
      }
      .bowl {
        width: 18vh;
      }
    }
    .control-desc{
        font-size: 4vh;
        margin: 4vh auto 0;
        max-width: 75vw;
    }
    @media only screen and (max-width: $screen-md-min) {
      padding-top: 12vh;
      .control-animation {
        .bowl {
          width: 14vh;
        }
      }
      .control-desc{
        font-size: 2vh;
        margin: 6vh auto 0;
        max-width: 66vw;
      }
    }
  }
}

$translation: 26vw;

@keyframes instructions-bowl-animation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-$translation);
  }
  75% {
    transform: translateX($translation);
  }
  100% {
    transform: translateX(0);
  }
}
