
.TideReactTable{
  position: relative;

  table{
    width: 100%;
  }
  .table th{
    border-top: none;
    background-color: rgba(0, 0, 0, 0.24);
  }
  .table-footer{
    display: block;
    font-size: 0.8em;
    .register-count{
      float: left;
    }
  }
  .spacer{
    height: 10em;
  }
  .over-message{
    font-size: 1.6em;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -2em;
    width: 100%;
    span{
      display: inline-block;
      padding: 20px;
      color: white;
    }
    .LoadingIcon{
      max-width: 100px;
      margin: auto;
    }
  }
  .table td, .table th {
    vertical-align: middle;
    padding: 10px;
  }

  .table tr:hover{
  }

  /** Table rows with different color depending if is pair  */
    .table tr:nth-child(even){
        background-color: rgba(245, 245, 245, 0.12);
    }
}
