@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";
@import "./game-sizes";

.Game{
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  &.over{
    position: relative;
  }

  &.GeneralContainer{
    background-image: url("../../../assets/images/backgrounds/back_avatar_web.jpg");
  }
  .game-header{
    height: 100vh - $game-height;
    @media only screen and (max-width: $screen-md-min) {
      height: 100vh - $game-height-mob;
    }
    .logo{
      position: absolute;
      top: 3vh;
      left: 3vw;
      height: 14vh;
      @media only screen and (max-width: $screen-md-min) {
        height: 8vh;
      }
    }
    .top-right{
      position: absolute;
      right: 0;
      top: 0;
      height: 20vh;
      @media only screen and (max-width: $screen-md-min) {
        display: none;
      }
    }
    .lives-container{
      background: url("./assets/layout/lives-holder.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: 16vw;
      top: 8vh;
      width: $lives-height * 3.11;
      height: $lives-height;
      font-size: $lives-height * 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: $lives-height;
      
      @media only screen and (max-width: $screen-md-min) {
        right: 5vw;
        top: 3vh;
        width: $lives-height-mob * 3.11;
        height: $lives-height-mob;
        font-size: $lives-height-mob * 0.5;
        padding-left: $lives-height-mob;
      }

      margin-right: 65px;
      
      @include md {
        margin-right: 0;
      }
    }
  }
  .game-container{
    height: $game-height;
    padding: 1px;
  }
  .game-footer{
    position: absolute;
    bottom:0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
    
    @media only screen and (max-width: $screen-md-min) {
      bottom: 5vh;
    }
    .corner{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 18vh;
      &.right{
        left: initial;
        right: 0;
      }
    }
    .bottom{
      width: 100vw;
    }
  }
  @media only screen and (max-width: $screen-md-min) {
    &.over .game-footer{
      bottom: 0;
    }
    .game-container{
        height: initial;
    }
  }
}
