@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.ResetPassword {

  position: relative;

  .left-column {
    padding-top: 40px !important;
    width: 45%;
    float: left;
    position: relative;
    z-index: 12;
    @media (max-width: 768px) {
      width: 100%;
      margin-left: -20px;
      padding-top: 50px !important;
    }
  }

  .right-column {
    position: relative;
    z-index: 20;
    width: 55%;
    float: left;
    font-size: 1.8em;
    text-align: center;
    padding-top: 350px !important;
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 150px !important;
    }


    form {
      .login-button {
        margin-top: 2vw;
      }

      input {
        color: white;
      }
    }
  }

  .popcorns-image {
    max-height: 100%;
    position: fixed;
    bottom: 0;
    @media (max-width: 768px) {
      max-height: 60%;
    }

  }

  .footer-left-image {
    position: absolute;
    width: 25vw;
    bottom: 0;
    left: 0;
    @media (max-width: 768px) {
      display: none;

    }

  }

  .footer-right-image {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .footer-mobile{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @media (max-width: 768px) {
      display: block;
    }
  }

}
