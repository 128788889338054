@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.Contact {

  text-align: center;
  position: relative;

  .container{
    padding-top: 120px !important;
  }

  h1{
    font-size: 3em;
    margin-bottom: 0;
  }

  .instructions{
    font-size: 1.1em;
    img{
      width:1.3em;
      top:4px ;
      position: relative;
    }
  }

  form{
    width: 40%;
    min-width: 350px;
    margin: auto;
    .TextInput .input-el, .TextArea .input-el{
      width: 100%;
    }
  }
  .bottom-left-image{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 18vw;
    @media (max-width: 768px){
      display: none;
    }
  }
  input, textarea{
    font-family: PlutoRegular, sans-serif;
    &::placeholder{
      font-family: PlutoRegular, sans-serif;
    }
  }

  .bottom-right-image{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18vw;
    @media (max-width: 768px){
      display: none;
    }
  }


}
