

.recipe-popup{
  position: fixed;
  z-index: 33;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100vh;
  padding-top: 80px !important;
  .recipe-container{
    width: 70%;
    margin: auto;
    text-align: center;
    position: relative;
    padding-bottom: 100%;
    img{
      max-width: 100%;
    }
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
      width: 90%;
      padding-bottom:200%;
    }
    &.recipe1 {
      background-image: url("../../../../assets/images/home/recetas_malteada_web.jpg");
      @media (max-width: 768px) {
        background-image: url("../../../../assets/images/home/mobile/receta_malteada_mobile.jpg");
      }
    }
    &.recipe2 {
      background-image: url("../../../../assets/images/home/receta_palomitas_web.jpg");
      @media (max-width: 768px) {
        background-image: url("../../../../assets/images/home/mobile/receta_palomitas_mobile.jpg");
      }
    }
    &.recipe3{
      background-image: url("../../../../assets/images/home/receta_hotcakes_web.jpg");
      @media (max-width: 768px) {
        background-image: url("../../../../assets/images/home/mobile/receta_hotcakes_mobile.jpg");
      }
    }
    &.recipe4{
      background-image: url("../../../../assets/images/home/receta_carlota_web.jpg");
      @media (max-width: 768px) {
        background-image: url("../../../../assets/images/home/mobile/receta_carlota_mobile.jpg");
      }
    }

    position: relative;
    .close{
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}