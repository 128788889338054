@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/mixins";

.Dynamic {
    width: 100%;
    background-image: url("../../../assets/images/dynamic/mobile/ondas_dinamica_mobile.png"), url("../../../assets/images/background_oreo.jpg");
    background-repeat: no-repeat, no-repeat;
    background-size: contain, cover;
    background-position: right bottom , left;

    @include md {
        background: url("../../../assets/images/dynamic/pleca_izquierda_dinamica_web.png"), url("../../../assets/images/background_oreo.jpg");
        background-repeat: no-repeat, no-repeat;
        background-size: 596px 140px, 100% 100%;
        background-position: left bottom , left;
    }
        
    .Menu, .BackButton {
        display: none;

        @include md {
            display: flex;
        }
    }

    .main-column {       
        padding-bottom: 20px;
        position: relative;
        z-index: 24;
        flex: none; 
        padding-top: 0!important;

        @include md {
            flex: 1;
            padding-top: 150px !important;
        }

        &.left {
            padding-left: 0px;
            margin-top: 0px;            
            width: 100%;
            flex: none;
            display: inline-block;
            margin-left: auto;
            margin-right: auto;

            @include md {
                flex: 1;
                padding-left: 90px;
                margin-top: 72px;
                padding-top: 100px !important;
            }
        }

        &.right {
            display: none;

            @include md {
                display: block;
            }
        }
    }

    .title {
        font-size: 40px;
        margin: 0;
        margin-top: 20px;
        padding: 0;
        text-align: center;

        @include md {
            font-size: 90px;
            text-align: left;
            margin-top: 80px;
        }
    }


    &__galletas {
        position: fixed;
        z-index: 1;
        bottom: 0;
        right: 0;
        max-width: 100%;

        @media (max-width: 768px) {
            display: none;            
        }

        @include md {
            max-width: 50%;
        }
    }

    &__galletas-mobile {
        display: block;
        width: 100%;
        margin-top: -10px;
        
        @include md {
            display: none;
        }
    }

    &__process {
        display: grid;
        gap: 1px;
        grid-template-columns: repeat(3, 1fr);

        &__col {
            background-color: #ccc;
        }

        &__title {
            font-family: "PlutoHeavy";
            font-size: 16px;
            font-weight: bolder;
            text-align: center;

            @include md {
                font-size: 24px;
            }
        }
    }

    &__terms {
        padding: 20px;
        text-align: center;
        font-size: 14px;

        &::before {
            content: "• ";
            margin-right: 20px;
        }

        & > a {
            text-decoration: underline !important;
        }

        @include md {
            font-size: 20px;
        }
    }

    .buy-image {
        width: auto;
        height: 90px;

        @include md {
            height: auto;
        }
    }

    @include md {
        .cookies-box {
            width: 100%;
            max-width: 154px;        
            height: auto;
        }

    }
    
    &__home-button {
        display: block;
        margin: 20px auto;
        background-color: transparent;
        border: 0;

        @include md {
            display: none;
        }
    }

    .first-image {
        text-align: left;

        .Dynamic__process__title {
            text-align: left;
        }
    }

    .last-image {
        text-align: right;

        .Dynamic__process__title {
            text-align: right;
        }
    }

    .process_title {
        text-align: center;
    }

    .process_img {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}