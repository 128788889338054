@import "src/assets/styles/breakpoints";

.GeneralContainer.ShareScreen{
  display: block;
  text-align: center;
  position: relative;

  .share-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $screen-md-min) {
      flex-direction: column;
      padding-top: 100px;
    }
    .side{
      padding: 0 5vw;
    }
    .friends-icon{
      width: 600px;
      max-width: 75vw;
    }
    .title{
      font-size: 5em;
      font-family: PlutoRegular, sans-serif;
      margin: 20px;
      @media only screen and (max-width: $screen-md-min) {
        font-size: 2em;
      }
    }
    .link{
      background: #004e95;
      display: inline-block;
      padding: 10px 50px;
      border-radius: 25px;
      box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.5);
      font-size: 1.2em;
      .mini-oreo{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.5);
        background: url("../../../assets/images/galleta_oreo_mini.png") no-repeat center;
        background-size: 150% 150%;
        margin-right: 10px;
        vertical-align: middle;
      }
      @media only screen and (max-width: $screen-md-min) {
        font-size: 1em;
        padding: 10px 15px;
        .mini-oreo{
          width: 15px;
          height: 15px;
        }
      }
    }

    .share-btn {
      background: url("./assets/boton_compartir_web.png") no-repeat center;
      background-size: 100% 100%;
      color: white;
      border: none;
      font-size: 2.3em;
      font-family: PlutoBold, sans-serif;
      padding: 1.2em 3em;
      cursor: pointer;
      transition: transform 0.3s;
        &:hover{
            transform: scale(1.05);
        }
      @media only screen and (max-width: $screen-md-min) {
        font-size: 2em;
        padding: 1.2em 3em;
        margin-bottom: 80px;
      }
    }
  }
  .invitation-text{
    padding: 8vh 0;
    font-size: 2.8em;
    width: 900px;
    max-width: 80vw;
    margin: 0 auto;
    @media only screen and (max-width: $screen-md-min) {
      font-size: 1.4em;
      margin: 0;
      padding: 0;
    }
  }

  .game-footer{
    position: absolute;
    bottom:0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
    .corner{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 18vh;
      &.right{
        left: initial;
        right: 0;
      }
    }
    .bottom{
      width: 100vw;
    }
  }
}
