@font-face {
  font-family: "PlutoBold";
  src: url("./Pluto_Cond_Bold.otf");
}
@font-face {
  font-family: "PlutoHeavy";
  src: url("./Pluto_Heavy.otf");
}
@font-face {
  font-family: "PlutoRegular";
  src: url("./PlutoCondRegular.otf");
}
