.AdminMenu {
  max-width: 1130px;
  margin: 0 auto;
  a{
    margin-right: 20px;
  }
  margin-bottom: 50px;

  .menu-item {
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: 0.7s all ease-in-out;

    &:hover {
      border-bottom: 3px solid white;
    }
  }

  .active {
    border-bottom: 3px solid white;
  }
  .version{
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
