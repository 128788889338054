@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.ForgotPass {
  width: 100%;

  @include md {
    background: url("../../../assets/images/forgot-pass/pleca-derecha_recuperar_web.png"), url("../../../assets/images/forgot-pass/pleca_izquierda_recuperar_web.png"), url("../../../assets/images/background_oreo.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 50%, 50%, 100% 100%;
    background-position: right bottom, left bottom , left;
    padding: 0;
  }

  @include lg {
    background: url("../../../assets/images/forgot-pass/pleca-derecha_recuperar_web.png"), url("../../../assets/images/forgot-pass/pleca_izquierda_recuperar_web.png"), url("../../../assets/images/background_oreo.jpg");
    background-size: 524px 220px, 423px 136px, 100% 100%;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: right bottom, left bottom , left;
    padding: 0;
  }

  position: relative;

  &__title {
    font-size: 24px;
  }

  .left-column {
    padding-top: 40px !important;
    width: 60%;
    float: left;
    @media (max-width: 768px) {
      width: 100%;
      margin-left: -20px;
      padding-top: 50px !important;
    }
  }

  .right-column {
    position: relative;
    z-index: 20;
    width: 40%;
    float: left;
    font-size: 1.8em;
    text-align: center;
    padding-top: 350px !important;
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 0 !important;
      margin-top: -50px
    }


    form {
      .login-button {
        margin-top: 2vw;
      }

      input {
        color: white;
      }
    }
  }

  .popcorns-image {
    width: 376px;
    display: block;
    margin: 0 auto;
    max-width: 100%;

    @include md {
      width: 100%;
      display: inline;
    }
  }

  .footer-left-image {
    position: absolute;
    width: 25vw;
    bottom: 0;
    left: 0;
    @media (max-width: 768px) {
      display: none;
    }

  }

  .footer-right-image {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .footer-mobile{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @media (max-width: 768px) {
      display: block;
    }
  }

}
