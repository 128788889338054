@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.EmailSent{

  position: relative;

  .left-column {
    padding-top: 120px !important;
    padding-left: 80px;
    width: 60%;
    float: left;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-top: 100px !important;
    }
  }

  .right-column {
    position: relative;
    z-index: 20;
    width: 40%;
    float: left;
    font-size: 1.8em;
    text-align: center;
    padding-top: 350px !important;
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 0 !important;
      margin-top: -50px
    }


    form {
      .login-button {
        margin-top: 2vw;
      }

      input {
        color: white;
      }
    }
  }

  .brownie-image {
    max-width: 100%;
  }

  .footer-left-image {
    position: absolute;
    width: 25vw;
    bottom: 0;
    left: 0;
    @media (max-width: 768px) {
      display: none;
    }

  }

  .footer-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .footer-mobile{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .oreo-button{
    margin-top: 20px;
  }

  .sent-email-message{
    text-align: center;
    width: 300px;
    margin:auto;
  }
}
