@import "src/assets/styles/breakpoints";

.GameOver {

  .confetti{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }

  .center {
    width: 900px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto;

    .recipe-container {
      height: 140px;
      position: relative;
      width: 100%;
      z-index: 0;
      @media only screen and (max-width: $screen-md-min) {
        height: 80px;
      }

      .recipe-layout {
        position: absolute;
        width: 100%;
        bottom: -20px;
      }

      .recipe-image {
        width: 320px;
        position: relative;
        left: -1%;
        @media only screen and (max-width: $screen-md-min) {
            width: 200px;
        }
      }
    }

    .result-container {
      position: relative;
      z-index: 1;
      background: url("./assets/cuadro_resultado_web.png") no-repeat center center;
      background-size: 100% 100%;
      padding: 70px 70px;
      font-size: 1.2em;
      @media only screen and (max-width: $screen-md-min) {
        padding: 40px 40px;
        font-size: 1em;
      }

      .cookies-container {
        display: flex;
        justify-content: space-between;
        height: 50px;
        position: relative;
        bottom: 80px;

        @media only screen and (max-width: $screen-md-min) {
          bottom: 50px;
          width: 80%;
          margin: auto;
        }
        .cookie {
          background: #034fb3;
          box-shadow: -2px 6px 5px rgba(5, 49, 154, 0.9);
          width: 110px;
          height: 110px;
          border-radius: 50%;
          @media only screen and (max-width: $screen-md-min) {
            width: 75px;
            height: 75px;
          }
          &.second {
            transform: scale(1.4);
            @media only screen and (max-width: $screen-md-min) {
              transform: scale(1.3);
            }
          }
          &.active{
            background: url("../../../../../assets/images/galleta_oreo_perfil_web.png") no-repeat center center;
            background-size: 103% 103%;
          }
        }
      }

      .congrats {
        font-size: 2.4em;
        margin: 0.5em 0 0 0;
        font-weight: bold;
        color: #fff;
      }
      .points {
        font-size: 1.5em;
        margin: 0 0 0.5em 0;
        font-family: PlutoHeavy, sans-serif;
        background: rgba(0, 0, 0, 0.35);
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
        display: inline-block;
        padding: 0.5em 1.4em;
        border-radius: 30px;
      }

      .btn-container {
        max-height: 100px;
        margin-top: 20px;
        @media only screen and (max-width: $screen-md-min) {
          max-height: 80px;
        }
        .btn {
          font-size: 1.2em;
          @media only screen and (max-width: $screen-md-min) {
            font-size: 1em;
          }
          &.first {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .buy-block{
    position: absolute;
    top: 40vh;
    right: 50px;
    @media only screen and (max-width: $screen-md-min) {
      position: relative;
      top: 0;
      right: 0;
      padding: 40px 0 50px;
    }
    .buy-text{
      font-family: PlutoHeavy, sans-serif;
      font-size: 2em;
      margin: 0.1em;
      @media only screen and (max-width: $screen-md-min) {
        font-size: 1em;
      }
    }
    .buy-line{
      height: 2px;
      background: white;
    }
    @media only screen and (max-width: $screen-md-min) {
      .buy-img{
        height: 50px;;
      }
    }
  }
}
