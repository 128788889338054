@import "../../../assets/styles/breakpoints";

.GeneralContainer.UserHome{
  display: block;
  position: relative;
  min-height: 100vh;
  background-image: url("../../../assets/images/backgrounds/back_avatar_web.jpg");
  @media only screen and (max-width: $screen-md-min) {
    background-image: url("../../../assets/images/backgrounds/background_mobile_top_avatar.jpg");
  }
  .home-container{
    display: flex;
    @media only screen and (max-width: $screen-md-min) {
      flex-direction: column;
      padding-top: 100px;
    }
    .home-col{
      flex: 1;
      display: flex;
    }
    .user-container{
      align-items: center;
      flex-direction: column;
      .avatar-img{
        width: 14vw;
        position: relative;
        top: 2vw;
        @media only screen and (max-width: $screen-md-min) {
          width: 30vw;
          top: 4vw;
        }
      }
      .user-info{
        background: white;
        color: black;
        border: 5px solid #00bbf6;
        border-radius: 1.2vw;
        position: relative;
        padding: 1vw 1vw 1vw 4vw;
        font-size: 1.2vw;
        min-width: 20vw;
        @media only screen and (max-width: $screen-md-min) {
          font-size: 3.5vw;
          border-radius: 3vw;
          padding: 2vw 2vw 2vw 9vw;
        }
        .oreo{
          position: absolute;
          height: 6.4vw;
          top: -0.5vw;
          left: -3vw;
          @media only screen and (max-width: $screen-md-min) {
            height: 16vw;
            top: -1vw;
            left: -8vw;
          }
        }
        .name{
          font-size: 1.08em;
        }
        .user-title{
          font-size: 0.9em;
          font-family: PlutoRegular, sans-serif;
          margin-left: 0.5em;
        }
        .bot-data{
          margin-top: 0.2em;
        }
        .points{
          color: #064283;
          vertical-align: middle;
        }
        .lives{
          background-color: #b7cbe6;
          color: #443f40;
          font-size: 0.9em;
          padding: 0.15em 0.8em 0.15em 1.4em;
          vertical-align: middle;
          border-radius: 0.7em;
          box-shadow: inset #96b8e2 0 2px 1px 1px;
          margin-left: 0.8em;
          position: relative;
          .small-heart{
            position: absolute;
            height: 2em;
            top: -0.2em;
            left: -0.8em;
          }
        }
        .edit-btn{
          position: absolute;
          width: 2vw;
          top: -0.5vw;
          right: -0.5vw;
          padding: 0;
          background: none;
          border: none;
          cursor: pointer;
          .edit-img{
            width: 100%;
          }
          @media only screen and (max-width: $screen-md-min) {
            width: 8vw;
            top: -3vw;
            right: -3vw;
          }
        }
      }
    }
  }
  .routes-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 5vw;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: $screen-md-min) {
      padding-right: 0;
      margin-bottom: 120px;
    }
  }
  
  .route-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 2vw;
    width: 16vw;
    @media only screen and (max-width: $screen-md-min) {
      width: 38vw;
    }
    .route-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: white;
      font-size: 1.4vw;
      font-family: PlutoBold, sans-serif;
      cursor: pointer;
      margin-top: 20px;

      .route-icon{
        height: 7vw;
      }
      &.friends-block .route-name{
        font-size: 0.7em;
      }
      @media only screen and (max-width: $screen-md-min) {
        font-size: 4vw;
        .route-icon{
          height: 16vw;
        }
      }
    }
  }
  .play-container{
    padding: 2vw;
    text-align: center;
    .play-button{
      background: none;
      border: none;
      cursor: pointer;
      @media only screen and (max-width: $screen-md-min) {
        margin: 2vw 0;
      }
      .play-img{
        width: 18vw;
        @media only screen and (max-width: $screen-md-min) {
          width: 35vw;
        }
      }
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 26vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
}
