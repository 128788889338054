.LoginOrRegister {

  padding-top: 120px !important;

  .main-image {
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 45%;
    margin: auto;
    text-align: center;

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      width: 90%;
    }
  }

  .buttons-container {
    position: absolute;
    z-index: 2;
    margin: -40px auto auto;
    width: 100%;
    text-align: center;
    bottom: 0;
  }


  .enjoy-message {
    font-size: 2em;
    @media (max-width: 768px) {
      margin-top: 30px;
      margin-bottom: 5px;
      font-size: 1.5em;
    }
  }

  .footer-image {
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    line-height: 0;
    background-image: url("../../../assets/images/login-and-register/ondas_registro_incio_web.png");
    background-size: cover;
    height: 100vh;
    background-position: 50% 30%;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-image: url("../../../assets/images/login-and-register/mobile/onda_malteada.png");
      padding-bottom: 132%;
      bottom: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      line-height: 0;
      background-size: cover;
      height: 100vh;
      background-position: top;
      background-repeat: no-repeat;
      min-height: 700px;
    }


    img {
      width: 100%;
    }
  }

  .hotcakes{
    position: absolute;
    bottom: 0;
    z-index: 3;
    width: 20vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .cookie{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 10vw;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .btn-oreo-login {
    width: 100%;
  }
}
