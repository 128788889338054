@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.TicketSelectionModal{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  text-align: center;

  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .TicketSelectionModal__content{
    background: linear-gradient(341deg, rgba(0,128,223,1) 0%, rgba(0,75,193,1) 41%);
    width: 600px;
    max-width: 95vw;
    border: 6px solid white;
    border-radius: 8px;
    margin: 5vh auto;
    padding: 40px 0;
    position: relative;
    z-index: 1;

    @include md {
      padding: 40px;
    }

    .title{
      font-family: PlutoRegular, sans-serif;
      margin-top: 0;
    }
    .form{
      width: 85%;
      margin: auto;
    }
    .input {
      position: relative;
      width: 100%;
      color: white;
      background: #003a88;
      font-size: 1.4em;
      font-family: PlutoBold, sans-serif;
      padding: 0.8em 1.8em;
      text-align: left;
      border-radius: 2em;
      border: none;
      margin: 1em 0;
      &.select{
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 0.5em;
        cursor: pointer;
      }
    }
    .file-btn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &--mt {
        margin-top: 40px;
      }
    }
    .load-icon{
      width: 1.2em;
    }
    .file-in {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .text-input{
      &::placeholder{
        color: white;
      }
    }
  }

  &.showing-advice{
    .TicketSelectionModal__content{
      padding: 15px;
    }
  }

  .ticket-btn{
    color: white;
    background: none;
    border: none;
    font-family: PlutoBold, sans-serif;
    font-size: 1.4em;
    text-decoration: underline;
    cursor: pointer;
    .ticket-icon{
      vertical-align: middle;
      width: 1.5em;
    }
  }
  .ticket-example{
    width: 85%;
  }
  .action-button{
    margin-top: 1em;
  }

  .close-button {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;

    &:after{
      content: "\00d7";
      font-size: 60px;
    }
  }

  .ticket-disclamer {
    padding: 0 10px;
    margin-top: 20px;
  }
}
