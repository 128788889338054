@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.Login {
  padding-top: 0 !important;
  position: relative;

  &__left-column {
    position: relative;
    justify-content: left;
    align-items: end;
    z-index: 3;
    height: 100vh;
    float: left;
    width: 62%;


    @include md {
      display: flex;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__title {
    font-size: 27px;
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: PlutoBold, sans-serif;
  }

  &__right-column {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding-top: 180px;
    height: 100vh;
    text-align: center;
    width: 30%;
    float: left;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      padding-top: 130px;
    }

    & > form {
      width: 100%;
      max-width: 500px;


      @media (max-width: 768px) {
        width: 70%;
        max-width: 100%;
        font-size: 11px;
      }

    }
  }


  &__left-image {
    height: auto;
    width: 80%;
  }

  .login-button {
    cursor: pointer;
    background-image: url(../../../assets/images/login-and-register/boton_iniciar_web.png);
    background-position: center;
    background-size: cover;
    background-color: transparent;
    border: none;
    width: 264px;
    height: 156px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    @media (max-width: 768px) {
      width: 200px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      font-size: 15px;
    }
  }

  .space-between {
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    text-align: right;
  }

  .forgot-pass {
    text-decoration: underline !important;
  }

  .error-message {
    color: red;
    text-align: center;
    background-color: white;
    margin-top: 30px;
  }

  &__left-bottom-cookie {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;

    @include md {
      width: auto;
    }

    @media (max-width: 768px) {
      display: none;
      padding:0;
    }
  }

  &__right-bottom-cookie {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;

    @include md {
      width: auto;
    }

    @media (max-width: 768px) {
      display: none;
      padding: 0;
    }

  }

  .TextInput .input-el {
    color: white;
    font-family: PlutoBold, sans-serif;
    width: 100% ;


  }
  .footer-mobile{
    display: none;
  }
  .forgot-pass-left{
    float: left;
  }
}

.GeneralContainer--display-block {
  display: block !important;
}

.login-footer-mobile{
  display: block;

  & > img {
    margin-top: -50px;
    width: 100%;
    height: auto;
    margin-bottom: -6px;
  }

  @include md {
    display: none;
  }
}
