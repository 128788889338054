
//web
$game-height: 80vh;
$lives-height: 8vh;
$frame-width: 88vw;
$frame-height: 72vh;

//mobile
$lives-height-mob: 4vh;
$game-height-mob: 80vh;
$frame-height-mob: 58vh;
