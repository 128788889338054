@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.EndPromo {
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/images/end_promo/Oreo_Placeholder_mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include md {
        background-image: url('../../../assets/images/end_promo/Oreo_Placeholder.png');
    }
}