.Home {

  .malteada-mobile{
    display: none;
    @media (max-width: 768px) {
      display: block;
      width: 115%;
      margin-top: -100px;
      position: relative;
      z-index: 0;
      left: -10%;
    }
  }
  .main-column {
    padding-top: 150px !important;
    flex: 1;
    padding-bottom: 20px;
    position: relative;
    z-index: 24;
    @media (max-width: 768px) {
      flex: none;
    }

    &.left {
      @media (max-width: 768px) {
        width: 90%;
        flex: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 100px!important;
      }

      .cohete {
        position: fixed;
        z-index: 0;
        bottom: 0;
        left: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }

      .main-content {
        z-index: 3;
        position: relative;
        padding-left: 10vw;
        @media (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    &.right {
      z-index: 0;
    }
  }

  .milkshake {
    position: fixed;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 65%;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .video-iframe {
    width: 100%;
    height: 18vw;
    border: 5px solid white;
    border-radius: 5px;
    @media (max-width: 768px) {
      height: 45vw;
    }
  }

  .banner {
    width: 100%;
  }

  .recipes {

    .recipes-header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 10px;

      .recipe-title {
        font-size: 30px;
        font-family: PlutoHeavy, sans-serif;
        font-weight: 600;
        margin: 0;
        color: white;
        @media (max-width: 768px) {
          font-size: 15px;
        }

        .emoji {
          width: 45px;
          margin: 0 40px 0 0;
          @media (max-width: 768px) {
            margin: 0 10px 0 0;
          }

          &.right {
            margin: 0 0 0 40px;
            @media (max-width: 768px) {
              margin: 0 0 0 10px;
            }
          }
        }

        > * {
          vertical-align: middle;
        }
      }

      .doodle-onda {
        position: absolute;
        top: -2vw;
        width: 2.4vw;

        &.left {
          left: -1.4vw;
          transform: rotate(90deg);
        }

        &.right {
          right: -1.4vw;
        }
      }
    }

    .recipe-list {
      display: flex;
      justify-content: space-around;
    }

    .recipe-item {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;

      .recipe-img {
        width: 90%;
      }
    }
  }
  .footer-mobile{
    display: none;
    width: 115%;
    position: absolute;
    left: -10%;
    bottom: 0;
    @media (max-width: 768px) {
      display: block;

    }
  }

  .promotion-end-placeholder {
    width: 100%;
    height: 18vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .scrollhidden {
    overflow: hidden;
  }
}
