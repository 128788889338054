

.TideReactTable-Pagination{
  text-align: center;
  color: white;
  .pagination-btn{
    display: inline-block;
    min-width: 2.2rem;
    min-height: 2.2rem;
    margin: 0 0.2rem;
    border: none;
    background: none;
    &.pag-ctrl{
      font-size: 1.4em;
    }
    &.active{
      color: white;
    }
  }
  button:disabled{
    color: #949494;
  }
}
