@import "../../../../assets/styles/breakpoints";

.GeneralContainer.RankingList{

  .ranking-container{
    width: 100%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $screen-md-min) {
      flex-direction: column;
      padding-top: 80px;
    }
  }

  .side{
    flex: 1;
    @media only screen and (max-width: $screen-md-min) {
      flex: initial;
    }
  }
  .cover-container{
    text-align: right;
    padding-right: 5vw;
    .ranking-cover{
      width: 26vw;
    }
    @media only screen and (max-width: $screen-md-min) {
      text-align: center;
      padding-right: 0;
      .ranking-cover{
        width: 60vw;
      }
    }
  }
  .ranking-list{

    @media only screen and (max-width: $screen-md-min) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 150px;

    }

    .ranking-row{
      background: #004b93;
      width: 450px;
      max-width: 90vw;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 20px;
      box-shadow: inset 0 2px 4px 1px #001e70;
      margin: 10px;
      @media only screen and (max-width: $screen-md-min) {
        margin: 5px;
      }

      .first-cell{
        flex: 5;
      }
      .second-cell{
        flex: 2;
        text-align: center;
      }

      .mini-oreo{
        width: 1.5em;
        vertical-align: middle;
        margin-right: 0.4em;
        box-shadow: -2px 3px #001e70;
        border-radius: 50%;
      }
    }
  }

  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 22vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
  .empty-state{
    font-size: 1.2em;
    text-align: center;
    padding-top: 18vh;
    margin-right: 12vw;
  }
}
