.GeneralContainer{
    background: url("../../../assets/images/background_oreo.jpg");
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;

    &.with-menu>*:not(.Menu){
        padding-top: 190px;
    }
    &.hide-back-on-mobile{
        @media (max-width: 768px) {
            .BackButton{
                display: none;
            }
        }
    }

    &.hide-menu-on-mobile{
        @media (max-width: 768px) {
            .mobile-toggle{
                display: none;
            }
        }
    }
    .unicorn{
        position: absolute;
        top: 0;
        right: 0;
        width: 50vw;
        padding: 0 !important;
        @media (max-width: 768px) {
            display: none;
        }
    }
}
