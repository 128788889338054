@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.BackButton {
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  padding-top: 0 !important;
  top: 120px;
  right: 140px;
  z-index: 30;
  @media (max-width: 768px) {
    top: 26px;
    right: 0px;
  }
  img{
    width:50%;

    @include md {
      width:80%;
    }
  }
}
