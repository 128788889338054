@import "../../../../../assets/styles/breakpoints";

.ProductSelectionModal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  .overlay{
    background: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .content{
    width: 900px;
    max-width: 95vw;
    background: rgb(0,128,223);
    background: linear-gradient(341deg, rgba(0,128,223,1) 0%, rgba(0,75,193,1) 41%);
    border-radius: 10px;
    border: 5px solid white;
    margin: 5vh auto;
    position: relative;
    z-index: 2;
    max-height: 90vh;
    overflow: auto;
  }
  .title{
    text-align: center;
    font-size: 2em;
    font-family: PlutoRegular, sans-serif;
    padding: 0 20px;
    @media only screen and (max-width: $screen-md-min) {
        font-size: 1.5em;
    }
  }
  .product-list{
    display: flex;
    font-size: 0.95em;
    @media only screen and (max-width: $screen-md-min) {
        flex-direction: column;
    }
    .product-col{
      padding: 0 20px;
    }
    .product{
      background: #00398a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1.5em;
      margin: 0.5em;
      border-radius: 2.2em;
      box-shadow: inset 0 3px 2px 1px rgba(0,0,0,0.2);
      cursor: pointer;
      .initial{
        display: flex;
        align-items: center;
      }
      .name-cont{
        display: flex;
        min-height: 2em;
        align-items: center;
      }
      .name{
        vertical-align: middle;
      }
      .plus-icon{
        width: 30px;
        margin-right: 4px;
        vertical-align: middle;
      }
      .count{
        display: inline-block;
        min-width: 2em;
        vertical-align: middle;
        font-size: 1.2em;
        font-family: PlutoHeavy, sans-serif;
      }
      .lives{
        margin-left: 5px;
      }
      &.active{
        background: #2b5693;
        border: 5px solid #417dd3;

      }
    }
  }
  .buttons{
    text-align: center;
    padding-bottom: 20px;
  }
  .tools{
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    span{
      display: inline-block;
      position: relative;
      top: -7px;
    }
    img{
      width: 30px;
      cursor: pointer;
    }
  }
}
