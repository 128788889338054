@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.PromotionFinishedModal {
    position: fixed;
    z-index: 33;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'PlutoRegular';

    &__content {
        width: 100%;
        max-width: 757px;
        height: 523px;
        background-image: url(./images/modal-background-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-left: 20px;
        margin: 2rem;
        border: 3px solid white;
        border-radius: 8px;
        position: relative;

        @include md {
            margin: 0;
            padding: 0 0 0 53px;
            background-image: url(./images/modal-background.png);
            border: 0;
        }
    }

    &__title {
        
        margin: 0;
        padding: 0;
        font-family: 'PlutoRegular';
        font-size: 20px;

        &--1 {
            margin-left: 0;
            margin-top: 227px;

            @include md {
                font-size: 40px;
                margin-left: 65px;
                margin-top: 200px;
            }
        }

        &--2 {
            margin: 0;
            
            @include md {
                font-size: 40px;
            }
        }
    }

    &__text1 {
        margin-top: 25px;
        font-size: 14px;
        font-family: 'PlutoRegular';
        max-width: 238px;

        @include md {
            font-size: 20px;
            max-width: 447px;
        }
    }

    &__text2 {
        margin-top: 25px;
        font-size: 14px;
        font-weight: bold;
        font-family: 'PlutoBold', sans-serif;
        line-height: 22px;
        max-width: 220px;

        @include md {
            font-size: 20px;
            max-width: 447px;
        }
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        z-index: 5;

        @include md {
            width: 60px;
            height: 60px;
        }
    }

    .button-x {
        pointer-events: none;
        @include md {
            display: none;
        }
    }

    .arrow-container-button {
        position: relative;
        min-width: 32px;
        display: inline-block;
    }

    .arrow-button {
        cursor: pointer;
        width: 20px;
        height: auto;
        position: absolute;
        top: -13px;
        left: 0;

        @include md {
            top: -21px;
            width: 30px;
        }
    }
}