@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/mixins";

.Thankyou {
    width: 100%;
    background: url("../../../assets/images/login/pleca_derecha_inicar_sesion_web.png"), url("../../../assets/images/thankyou/pleca_inferior_izquierda.png"), url("../../../assets/images/background_oreo.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 50%, 50%, 100% 100%;
    background-position: right bottom, left bottom , left;

    @include md {
        background: url("../../../assets/images/login/pleca_derecha_inicar_sesion_web.png"), url("../../../assets/images/thankyou/pleca_inferior_izquierda.png"), url("../../../assets/images/background_oreo.jpg");
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-size: 473px 239px, 501px 216px, 100% 100%;
        background-position: right bottom, left bottom , left;
        padding: 0;
    }

    &__container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0 auto;
    }

    &__content{
        width: 100%;
        max-width: 500px;
        height: 650px;
        background-image: url(../../../assets/images/thankyou/pastel_thank_you_page_web.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
        margin-bottom: 150px;

        @include xlg {
            margin-bottom: 0;
        }

        &__title {
            font-size: 28px;
            font-weight: bold;
            color: white;
            text-align: center;            
            width: 100%;
            max-width: 380px;
            margin: 0 auto;
            margin-top: 300px;
            font-family: 'PlutoHeavy';

            @include md {
                font-size: 30px;
                margin-top: 300px;
            }
        }

        &__description {
            font-family: 'PlutoRegular';
            font-size: 22px;
            color: white;
            text-align: center;
            width: 100%;
            max-width: 450px;
            margin: 0 auto;
            margin-top: 20px;
        }

        .oreo-button--with-mt {
            margin-top: 30px;
        }
    }
}