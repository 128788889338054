@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/mixins";

.GeneralContainer.TicketHistory{

  position: relative;
  .history-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 25vh;
    @media only screen and (max-width: $screen-md-min) {
      flex-direction: column;
      align-items: center;
      padding-top: 12vh;
    }

    .icon-side{
      text-align: center;

      @media only screen and (max-width: $screen-md-min) {
        flex: initial;
        .history-icon{
          width: 100px;
        }
      }

      @include md {
        margin-right: 150px;
      }

    }
    .table-side{
      @media only screen and (max-width: $screen-md-min) {
        font-size: 0.8em;
      }
      .table-frame{
        background: url("./assets/tabla_historial_ticket_web.png") no-repeat center center;
        background-size: 100% 100%;
        width: 500px;
        min-height: 32vh;
        padding: 30px;
        text-align: center;
        max-width: 90vw;
        @media only screen and (max-width: $screen-md-min) {
          margin-bottom: 280px;
        }
      }
      .table{
        max-height: 40vh;
        overflow: auto;
      }
      .table-header{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        .th{
          padding: 10px 0;
          flex: 1;
          &:last-child{
            flex: 0.7;
          }
        }
      }
      .table-row{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #002777;
        margin: 5px 20px;
        border-radius: 20px;
        .td{
          padding: 10px 0;
          flex: 1;
          &:last-child{
            flex: 0.7;
          }
        }
        .mini-oreo{
          width: 1.5em;
          vertical-align: middle;
          margin-right: 0.4em;
        }
      }
    }
  }


  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 26vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
}
