.GeneralContainer.PrivacyPolicy{
  display: block;
  .title{
    text-align: center;
  }
  .container{
    width: 1130px;
    max-width: 85vw;
    margin: auto;
    padding: 150px 30px;
  }
  td{
    padding: 5px;
  }
}
