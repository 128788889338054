@import "../../../assets/styles/breakpoints";

.GeneralContainer.Recipes{

  .recipes-container{
    margin: 0 auto;
    @media only screen and (max-width: $screen-md-min) {
      padding-top: 110px;
    }
  }
  .recipe-title{
    font-size: 3.5em;
    text-align: center;
    @media only screen and (max-width: $screen-md-min) {
        font-size: 1.6em;
    }
    .emoji{
      width: 1.6em;
      margin: 0 1em;
      @media only screen and (max-width: $screen-md-min) {
        width: 1.2em;
        margin: 0 0.2em;
      }
    }
  }
  .recipe-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    @media only screen and (max-width: $screen-md-min) {
      flex-direction: column;
    }
    .recipe-item{
      border: none;
      background: none;
      .recipe-img{
        width: 180px;
      }
    }
  }

  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 26vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }

}
