@import "../../../assets/styles/breakpoints";

.GeneralContainer.AvatarSelection{
  background-image: url("../../../assets/images/backgrounds/back_avatar_web.jpg");
  @media only screen and (max-width: $screen-md-min) {
    background-image: url("../../../assets/images/backgrounds/background_mobile_top_avatar.jpg");
  }
  .avatar-container{
    display: flex;
    width: 100%;
    @media only screen and (max-width: $screen-md-min) {
        display: block;
        padding-top: 100px;
    }
    .avatar-house{
      height: 21vw;
      width: 14vw;
      position: relative;
      @media only screen and (max-width: $screen-md-min) {
        height: 50vw;
        width: 30vw;
      }
    }
    .big-avatar{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .selected-block{
      flex: 0.9;
      display: flex;
      flex-direction: column;
      align-items: center;
      .select-btn{
        background: none;
        border: none;
        position: relative;
        top: -4vw;
        transition: transform 0.1s;
        cursor: pointer;
        @media only screen and (max-width: $screen-md-min) {
            top: -6vw;
        }
        &:hover{
          transform: scale(0.95);
        }
        .select-img {
          width: 30vw;
          @media only screen and (max-width: $screen-md-min) {
            width: 60vw;
          }
        }

      }
    }
    .options-block{
      flex: 1;
      .avatar-list-wrap{
        direction: rtl;
        width: 44vw;
        height: 55vh;
        overflow: auto;
        padding-left: 2vw;
        @media only screen and (max-width: $screen-md-min) {
            width: 100vw;
            padding: 4vw;
            height: 34vh;
        }
      }
      .avatar-list{
        direction: ltr;
        text-align: center;
        .avatar-item{
          display: inline-block;
          padding: 1vw 2.3vw;
          cursor: pointer;
          .avatar-opt-img{
            width: 5vw;
          }
          @media only screen and (max-width: $screen-md-min) {
            padding: 2vw 3.5vw;
            .avatar-opt-img {
              width: 13vw;
            }
          }
        }
      }
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background: #65a5e2;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #4598e8;
      }
      @media only screen and (max-width: $screen-md-min) {
        ::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track{
          background: initial;
        }
        ::-webkit-scrollbar {
          width: initial;
        }
      }

    }
  }
  .top-wave{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 45vw;
  }
  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 26vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
}
