@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.Menu{
  position: absolute;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
  z-index: 25;

  .menu-logo{
    width: 130px;
    @media (max-width: 768px) {
      width: 80px;
    }
  }
  .menu-item{
    font-size: 1.5vw;
    margin: 0 1.5vw;

  }
  .menu-final{
    width: 60px;
    position: absolute;
    top: -20px;
    right: -24px;
  }
  .desktop-menu{
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-toggle{
    position: absolute;
    right: 10vw;
    display: none;
    cursor: pointer;
    @media (max-width: 768px) {
      display: block;
      right: 10px;
    }
  }
  &.force-hamburger .mobile-toggle{
    display: block;
  }

  .popup-menu-container{
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;

    .menu-options{
      border: 2px solid white;
      background: linear-gradient(138deg, rgba(0,79,196,1) 0%, rgba(28,105,219,1) 35%, rgba(73,175,241,1) 100%);
      width: 80%;
      max-width: 700px;
      margin: 80px auto;
      text-align: center;
      position: relative;

      &__rocket-web {
        display: none;
  
        @include md {
          display: block;
          position: absolute;
          bottom: -90px;
          right: -85px;
          width: 350px;
        }
      }
  
      &__rocket-mobile {
        display: block;
        width: 148px;
        bottom: -35px;
        right: -22px;
        position: absolute;
        
  
        @include md {
          display: none;
        }
      }

      .menu-item{
        font-size: 20px;
        display: block;
        border-bottom: 1px solid white;
        width: 80%;
        margin: auto auto 30px;
      }
      .close{
        text-align: right;
        padding-right: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

}
