@import "src/assets/styles/colors";

.TideEntitySelect{
  min-width: 150px;
  margin-top: 10px;
  max-width: 370px;
  border-radius: 8px;
  .DropdownIndicator{
    border: none;
    color: $text-color;
    padding-right: 15px;
    .caret{
      transition: transform 300ms;
      &.open{
        transform: rotate(180deg);
      }
    }
  }
  .tide-react-select{
    &__control {
      border: none;
      background: rgba(255, 255, 255, 0.1);
      max-width: 350px;
    }
    &__value-container {
      padding: 7px 17px;
    }
    &__indicator-separator {
      display: none;
    }
    &__menu{
      //border-radius: 0 0 20px 20px;
      overflow: hidden;
      padding: 0 15px !important;
      margin-top: 0 !important;
      //box-shadow: $default-shadow;
      top: unset;
    }
    &__menu-list{
      padding-top: 0;
    }
    &__option{
      &:first-of-type {
        //border-top: 1px solid $main-blue;
      }
      &, &--is-selected, &--is-focused, &--is-disabled{
        color: gray !important;
        padding-left: 0;
        position: relative;
        background: none;
      }
      &--is-focused{
        color: gray !important;
      }
      &--is-selected{
        color: black !important;
      }
      &--is-focused{
        cursor: pointer;
      }
    }
    &__placeholder {
      color: $text-color;
      font-weight: bold;
    }
  }
}
