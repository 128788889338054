@import "src/assets/styles/breakpoints";
@import "../../game-sizes";

.GamePlay{
  position: relative;

  .top-game{
    height:0;
    position: relative;

    .sound-btn{
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      left: 9vw;
      top: 0;
      .speaker{
        width: 8vh;
      }
      @media only screen and (max-width: $screen-md-min) {
        top: -18vh;
        left: initial;
        right: 45vw;
        .speaker{
          width: 6vh;
        }
      }
    }

    .avatar{
      width: 11vh;
      z-index: 0;
      position: absolute;
      left: 15vw;
      top: -4vh;
      @media only screen and (max-width: $screen-md-min) {
        width: 8vh;
        top: -2.5vh;
      }
    }
    .game-name{
      position: absolute;
      left: 28vw;
      top: -3vh;
      font-size: 3vh;
      text-align: center;
      .pre{
        font-size: 0.8em;
      }
      @media only screen and (max-width: $screen-md-min) {
        right: 5vw;
        top: -3.2vh;
        font-size: 2.5vh;
      }
    }
    .recipe-thumb{
      position: absolute;
      left: 50vw;
      bottom: -8vh;
      width: 18vh;
      @media only screen and (max-width: $screen-md-min) {
        right: 20vw;
        bottom: 1vh;
        width: 12vh;
      }
    }

    .time-container {
      position: absolute;
      right: 11vw;
      top: -2vh;
      font-size: 3vh;

      .time {
        font-size: 2em;
        text-align: center;
      }
      @media only screen and (max-width: $screen-md-min) {
        right: initial;
        left: 14vw;
        font-size: 1.6vh;
        top: -8vh;
        .time-title{
          display: none;
        }
      }
    }
  }
  .game-frame{
    background: url("../../assets/layout/tablero_juego_web.png") no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: $frame-height;
    padding: $frame-height*0.14 (100vw - $frame-width +4vw)/2 $frame-height*0.165;
    z-index: 1;
    position: relative;
    display: flex;
    @media only screen and (max-width: $screen-md-min) {
      background-image: url("../../assets/layout/tablero_juego_mobile.png");
      background-size: 120% 110%;
      height: $frame-height-mob;
    }

    .play-btn-container {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .start-btn {
        background: none;
        border: none;
        cursor: pointer;
        color: white;
        font-size: 2.5vh;
        font-family: PlutoRegular, sans-serif;
        .play-img {
          width: 20vh;
        }
      }
    }
    .canvas-container{
      flex:1;
    }
    .game-canvas{
      width: 100%;
      height: 100%;
    }
  }
  .bottom-game{
    position: absolute;
    bottom: -2vh;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 1;
    .ingredients{
      position: absolute;
      left: 11vw;
      top: -8vh;
      font-size: 2.2vh;
      display: flex;
      align-items: center;
      .big{
        font-size: 1.7em;
      }
      .ingredients-list{
        margin-left: 2vw;
      }
      .ingredient-image{
        height: 4vh;
        margin: 0 1vh;
      }
      @media only screen and (max-width: $screen-md-min) {
        width: 100%;
        font-size: 1.2vh;
        left: initial;
        justify-content: center;
        .ingredient-image{
          height: 3vh;
          margin: 0 0.5vh;
        }
      }
    }
    .points-block{
      position: absolute;
      display: flex;
      right: 15vw;
      bottom: -1.6vh;
      font-size: 3.7vh;
      align-items: center;
      .points{
        background: #0b4081;
        border: 0.8vh solid #1158AA;
        width: 16vw;
        text-align: center;
        border-radius: 5vh;
        margin-left: 1vw;
      }
      @media only screen and (max-width: $screen-md-min) {
        right: initial;
        width: 100%;
        justify-content: center;
        bottom: -4.5vh;
        font-size: 2.5vh;
        flex-direction: column-reverse;
        p{
          margin: 0;
        }
        .points{
          width: 50vw;
        }
      }
    }
  }
}
