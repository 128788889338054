.UsersList{
  .center{
    text-align: center;
  }
  .logout{
    float: right;
    margin-right: 40px;
  }
  padding-top: 50px;
  .react-select__option{
    color: black!important;
  }
  .filters{
    margin-bottom: 10px;

    .filter{
      max-width: 200px;
    }
  }
  .table-container{
    max-width: 1130px;
    margin: auto;
  }

  .btn-export {
    width: 200px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    border: 0;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}
