@import "../../../assets/styles/breakpoints";

.Footer{
  height: 0;
  bottom: 0;
  position: absolute;
  padding: 0 !important;
  width: 100%;
  z-index: 30;

  .footer-container {
    background: #04366D;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media screen and (max-width: $screen-md-min){
      flex-direction: column;
    }
  }

  .logo-container{
    display: flex;
    align-items: center;
    @media screen and (max-width: $screen-md-min){
      margin-bottom: 20px;
    }
  }
  .logo-mdlz{
    height: 40px;
    margin: 10px 20px 10px 30px;
  }

  .footer-menu{
    font-size: 1em;
    @media screen and (max-width: $screen-md-min){
      text-align: center;
      display: flex;
    }
  }
  .menu-item{
    padding: 0 15px;
    border-right: 2px solid white;
    &:last-child{
      border-right: none;
    }
    @media screen and (max-width: $screen-md-min){
      padding: 10px 8px;
    }
  }
  .version{
    position: absolute;
    right: 5px;
    bottom: 0;
    color: #006dd2;
    font-family: PlutoRegular, sans-serif;
    font-size: 0.8em;
  }
}
