.TicketsList{
  .center{
    text-align: center;
  }
  .logout{
    float: right;
    margin-right: 40px;
  }
  padding-top: 50px;
  .react-select__option{
    color: black!important;
  }
  .filters{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .filter{
      max-width: 250px;
    }
  }
  .table-container{
    max-width: 1130px;
    margin: auto;
  }
  #react-select-2-listbox{
    color: black !important;
  }

  .TextInput .input-el {
    margin-top: 0;
  }

  .tide-react-select {
    width: 250px;
  }

  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .btn-filter {
    width: 200px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    border: 0;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
  }
}
