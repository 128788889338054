@import "src/assets/styles/colors";

.TextInput{
  display: block;
  position: relative;

  .input-el{
    border: 0;
    width: 350px;
    max-width: 100%;
    padding: 12px 20px;
    border-radius: 3px;
    box-shadow: default-shadow;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 10px;
    color: white;

    &:focus{
      outline: none;
    }
    &[type="password"] {
      color: white !important;
    }
  }

  .placeholder{
    color: white;
    position: absolute;
    left: 20px;
    top: calc(50% - 0.5em);
    transition: font-size 200ms, top 200ms;
    pointer-events: none;
  }
  &.with-content{
    .placeholder{
      top: 10%;
    }
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-weight: bold;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}
