.GeneralContainer.TermsOfUse{
  display: block;
  .title{
    text-align: center;
  }
  .container{
    width: 1130px;
    max-width: 85vw;
    margin: auto;
    padding: 150px 30px;
  }

  .list-with-hyphen {
    list-style-type: none;

    & > li {
      &:before {
        content: '-';
        margin-right: 10px;
      }
    }
  }
}
