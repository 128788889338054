.TicketProductsList {
    .center{
        text-align: center;
    }
    .logout{
        float: right;
        margin-right: 40px;
    }
    padding-top: 50px;
    .react-select__option{
        color: black!important;
    }

    .table-container{
        max-width: 1130px;
        margin: auto;
    }
}