@import "src/assets/styles/colors";

.DateSelector {
    margin-top: 10px;
    max-width: 355px;
    display: flex;
    justify-content: center;
    align-items: center;
  .label-date{
    max-width: 80px;
    margin-right: 10px;
  }
    
  .DropdownIndicator{
    border: none;
    color: $text-color;
    // font-size: 18px;
    padding-right: 15px;
    .caret{
      transition: transform 300ms;
      &.open{
        transform: rotate(180deg);
      }
    }
  }
  .tide-react-select{
   margin-right: 10px;

    &__control {
      border: none;
      background: rgba(255, 255, 255, 0.2);
      max-width: 80px;

      &--menu-is-open {
        border: 25px;
      }
    }
    &__value-container {
      padding: 7px 4px;
      min-width: 40px;
      font-size: 12px;
    }
    &__indicator-separator {
      display: none;
    }
    &__menu{
      // border-radius: 0 0 20px 20px;
      overflow: hidden;
      padding: 0 5px !important;
      margin-top: 0 !important;
      //box-shadow: $default-shadow;
      top: unset;
     
    }
    &__menu-list{
      padding-top: 0;
    }
    &__option{
      &:first-of-type {
        //border-top: 1px solid $main-blue;
      }
      &, &--is-selected, &--is-focused, &--is-disabled{
        color: gray !important;
        padding-left: 0;
        position: relative;
        background: none;
      }
      &--is-focused{
        color: gray !important;
      }
      &--is-selected{
        color: black !important;
      }
      &--is-focused{
        cursor: pointer;
      }
    }

    &__placeholder {
      color: $text-color;
      font-weight: bold;
    }

  }
}