@import "../../../assets/styles/breakpoints";

.OreoButton{
  background: url("../../../assets/images/buttons/boton_back.png") no-repeat center center;
  background-size: 120% 120%;
  border: none;
  padding: 2em 4em;
  color: white;
  font-family: PlutoBold, sans-serif;
  font-size: 1.6em;
  cursor: pointer;
  transition: transform 0.25s;
  &.rounded{
    background-image: url("../../../assets/images/buttons/boton_redondeado_web.png");
    background-size: 100% 100%;
    padding: 1.4em 2.5em;
  }
  &:disabled, &[disabled]{
    background-image: url("../../../assets/images/buttons/boton_back_gris.png");
  }
  &:hover{
    transform: scale(1.05);
  }
  @media only screen and (max-width: $screen-md-min) {
    font-size: 1.2em;
  }
}
