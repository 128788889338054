@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";
@import "src/assets/styles/breakpoints";

.register-container {
  background: #0256C8 url("../../../assets/images/register/Background_glow_registro_web.jpg") no-repeat center;
  background-size: cover;

}

.Register {
  font-family: PlutoHeavy, sans-serif;

  &__container {
    display: flex;
    width: 100%;
    padding-top: 140px !important;
    position: relative;
    @media (max-width: 768px) {
      padding-top: 100px !important;
    }
  }

  &__cookie-image {
    padding-top: 70px;
    max-width: 60%;
    margin: 0 auto;
    display: block;
  }

  &__cookie-left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__cookie-right-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__left-column {
    width: 70%;
    display: none;
    @media (max-width: 768px) {
      width: 0;
      display: block;
      .Register__cookie-image, .Register__cookie-left-bottom {
        display: none;
      }

    }

    @include md {
      display: block;
    }
  }

  &__right-column {
    width: 40%;
    padding-top: 30px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .form-container {
      position: relative;
      z-index: 3;
      margin-bottom: 120px;
      @media (max-width: 768px) {
        margin: auto;
      }
    }
  }

  .Register__cookie-right-bottom {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .side-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
  }

  .form {
    width: 100%;
    max-width: 370px;
    @media (max-width: 768px) {
      width: 80%;
      margin: auto;
    }
  }

  .form-container {
    width: 440px;
    max-width: 90%;
  }

  .subtitle {
    color: green;
    font-family: Nunito-ExtraBold, sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .submit {
    width: 100%;
    margin-bottom: 30px;
  }

  .sign-up-text {
    text-align: center;
    margin-top: 6px;
    font-family: Nunito-SemiBold, sans-serif;
  }

  .sign-up-link {
    font-family: Nunito-Bold, sans-serif;
    text-decoration: underline;
  }

  .terms-text {
    font-size: 12px;
    margin: 0 0 7px 0;
    max-width: 350px;

    .Checkbox {
      margin-right: 16px;
    }

    &--right {
      text-align: right;
    }
  }

  .right-side {
    align-items: end;
    @media only screen and (max-width: 768px) {
      align-items: start;
      padding-top: 20px;
    }
  }

  .text-block {
    color: white;
    font-family: Nunito-Bold, sans-serif;
    font-size: 31px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 40px 35px;
    width: 90%;
    max-width: 505px;
    line-height: 1.03em;
    margin-bottom: 40px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(6px);
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .remember {
    margin-top: 10px;
  }

  .terms-text {
    max-width: 350px;

    & > a {
      text-decoration: underline !important;
    }
  }

  .terms-text--right {
    text-align: right;
    max-width: 350px;
  }

  input {
    color: white;
    font-family: PlutoHeavy, sans-serif;

    &[type="checkbox"] {
      background: transparent;
      color: red;
    }
  }


  .tide-react-select__single-value {
    color: white;
  }

  .tide-react-select__dropdown-indicator, .tide-react-select__dropdown-indicator:hover {
    color: white;
  }

  .tide-react-select__input {
    color: white !important;
  }

  .label-date {
    font-size: 12px;
  }

  .footer-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }


  .gender{
    margin-top:10px;
    width: 350px;
    .tide-react-select__placeholder{
      padding-left: 12px;
    }
    .tide-react-select__single-value{
      padding-left: 12px;
    }
  }
  .tide-react-select {
    margin-right: 10px;

    &__control {
      border: none;
      background: rgba(255, 255, 255, 0.1);
      max-width: 100%;

      &--menu-is-open {
        border: 25px;
      }
    }

    &__value-container {
      padding: 7px 4px;
      min-width: 40px;
      font-size: 12px;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      // border-radius: 0 0 20px 20px;
      overflow: hidden;
      padding: 0 5px !important;
      margin-top: 0 !important;
      //box-shadow: $default-shadow;
      top: unset;

    }

    &__menu-list {
      padding-top: 0;
    }

    &__option {
      &:first-of-type {
        //border-top: 1px solid $main-blue;
      }

      &, &--is-selected, &--is-focused, &--is-disabled {
        color: gray !important;
        padding-left: 0;
        position: relative;
        background: none;
      }

      &--is-focused {
        color: gray !important;
      }

      &--is-selected {
        color: black !important;
      }

      &--is-focused {
        cursor: pointer;
      }
    }

    &__placeholder {
      color: $text-color;
      font-weight: bold;
    }

  }


  .finish-promo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


