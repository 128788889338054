@import "../../../assets/styles/breakpoints";

.GeneralContainer.TicketUpload{
  display: block;
  text-align: center;
  position: relative;
  .upload-container{
    padding-top: 140px;
  }
  .title{
    font-size: 3.2em;
    margin: 0;
    @media only screen and (max-width: $screen-md-min) {
        font-size: 2.4em;
    }
  }
  .subtitle{
    font-family: PlutoBold, sans-serif;
    font-size: 1.6em;
    @media only screen and (max-width: $screen-md-min) {
        font-size: 1.4em;
    }
  }
  .ticket-path{
    display: flex;
    align-items: center;
    justify-content: center;
    .action-button{
      background: none;
      border: none;
      cursor: pointer;
      &.ticket{
        position: relative;
      }
    }
    .action-icon{
      width: 180px;
      @media only screen and (max-width: $screen-md-min) {
        width: 110px;
      }
    }
    .arrow{
      width: 60px;
      margin: 0 20px;
      @media only screen and (max-width: $screen-md-min) {
        width: 40px;
        margin: 0 10px;
      }
    }
  }
  .selected{
    font-size: 1.4em;
    @media only screen and (max-width: $screen-md-min) {
        font-size: 1.1em;
    }
  }
  .format{
    @media only screen and (max-width: $screen-md-min) {
      font-size: 0.8em;
    }
  }
  .validate{

  }
  .products-btn{
    background: #00408b;
    border: none;
    color: #fff;
    font-size: 1.1em;
    font-family: PlutoBold, sans-serif;
    padding: 0.6em 3em;
    border-radius: 2em;
    box-shadow: inset 0 3px 3px 0 rgba(0,0,0,0.5);
    margin-top: 40px;
    cursor: pointer;
    @media only screen and (max-width: $screen-md-min) {
      margin: 25px 0 230px 0;
      font-size: 1em;
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    pointer-events: none;
    z-index: 0;
    .corner{
      width: 26vw;
      @media only screen and (max-width: $screen-md-min) {
        width: 100vw;
      }
    }
  }
}
